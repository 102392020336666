<script setup>
import { commerce } from "@/main";
import ProductsList from "../components/ProductList.vue";
import { useStores } from "@/stores/Stores";
</script>

<script>
export default {
  name: "app",
  components: {
    ProductsList,
  },
  data() {
    return {
      store: useStores(),
      localCart: null,
    };
  },
  methods: {
    fetchProducts() {
      commerce.products
        .list()
        .then((products) => {
          this.store.products = products.data;
        })
        .catch((error) => {
          console.log("There is an error fetching products", error);
        });
    },
    fetchCart() {
      commerce.cart
        .retrieve()
        .then((cart) => {
          this.store.cart = cart;
          this.localCart = cart;
          console.log(this.store.cart);
        })
        .catch((error) => {
          console.log("There is an error fetching the cart", error);
        });
    },
    handleAddToCart(productId, quantity) {
      commerce.cart
        .add(productId, quantity)
        .then((item) => {
          this.store.cart = item;
          this.localCart = item;
          this.store.showCart = true;
        })
        .catch((error) => {
          console.log("There is an error fetching the cart", error);
        });
    },
    generateCheckoutToken() {
      commerce.checkout
        .generateToken(this.store.cart.id, { type: "cart" })
        .then((token) => {
          this.store.checkoutToken = token;
          console.log(this.store.checkoutToken);
        })
        .catch((error) => {
          if (this.store.cart.total_items == 0) {
            console.error("The cart is empty");
            return;
          } else {
            console.log("There was an error in generating a token", error);
          }
        });
    },
  },
  watch: {
    localCart() {
      this.generateCheckoutToken();
    },
  },
  created() {
    this.fetchProducts();
    this.fetchCart();
  },
};
</script>

<template>
  <ProductsList
    :products="this.store.products"
    @add-to-cart="handleAddToCart"
  />
</template>
