import { createApp } from "vue";
import App from "./App.vue";
import "./assets/main.css";
import router from "./router/router.js";
// Import the Commerce object
import Commerce from "@chec/commerce.js";
import { createPinia } from "pinia";

const pinia = createPinia();
const app = createApp(App);

// Create a new Commerce instance
export const commerce =
  typeof process.env.VUE_APP_CHEC_PUBLIC_KEY !== "undefined"
    ? new Commerce(process.env.VUE_APP_CHEC_PUBLIC_KEY)
    : null;

app.use(router);
app.use(pinia);
app.mount("#app");
