<script setup>
import ProductItem from "./ProductItem.vue";
</script>

<script>
export default {
  name: "ProductsList",
  components: {
    ProductItem,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <div class="products grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-20">
    <ProductItem
      v-for="product in products"
      :key="product.id"
      :product="product"
      @add-to-cart="$emit('add-to-cart', $event)"
      class="products__item"
    />
  </div>
</template>
