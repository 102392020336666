<script>
export default {
  name: "ProductItem",
  props: ["product"], // data being passed down from a parent component to a child component is called props.
  methods: {
    addToCart() {
      this.$emit("add-to-cart", this.product.id, 1); // Emit is when a child component needs to pass data or propagate an event upstream to its parent.
    },
  },
};
</script>

<template>
  <div class="product__card border-4 text-center w-fit p-4 mx-auto">
    <img
      class="product__image w-80 h-80 mx-auto"
      :src="product.image.url"
      :alt="`Image of ${product.name}`"
    />
    <h3 class="product__name">{{ product.name }}</h3>
    <p class="product__description" v-html="product.description"></p>
    <p class="product__price">{{ product.price.formatted_with_code }}</p>
    <button
      class="product__btn mt-1 p-1 border-black border-2 hover:bg-green-500"
      @click="addToCart"
    >
      Add to Cart
    </button>
  </div>
</template>
