<script setup></script>

<script>
export default {
  name: "CartItem",
  props: ["item"],
  methods: {
    updateQuantity(quantity) {
      if (quantity < 1) {
        return this.removeFromCart();
      }
      this.$emit("update-quantity", this.item.id, quantity);
    },
    removeFromCart() {
      this.$emit("remove-from-cart", this.item.id);
    },
  },
};
</script>

<template>
  <div class="cart-item border-4 text-center w-fit p-4">
    <img class="cart-item__image w-80 max-h-80 mx-auto" :src="item.image.url" />
    <div class="cart-item__details">
      <h4 class="cart-item__details-name">{{ item.name }}</h4>
      <div class="flex justify-center p-2 text-lg">
        <button
          class="mr-[5%]"
          @click="() => updateQuantity(item.quantity - 1)"
        >
          -
        </button>
        <p>{{ item.quantity }}</p>
        <button
          class="ml-[5%]"
          @click="() => updateQuantity(item.quantity + 1)"
        >
          +
        </button>
      </div>
      <p class="cart-item__details-price">
        {{ item.line_total.formatted_with_code }}
      </p>
    </div>
    <button
      class="cart-item__remove mt-1 p-1 border-black border-2 hover:bg-red-500"
      @click="removeFromCart"
    >
      Remove
    </button>
  </div>
</template>
