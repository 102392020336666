<script setup>
import CartItem from "./CartItem.vue";
import { useStores } from "@/stores/Stores";
</script>

<script>
export default {
  name: "CartList",
  components: {
    CartItem,
  },
  data() {
    return {
      store: useStores(),
    };
  },
  methods: {
    sendQuantity(lineItemId, quantity) {
      this.$emit("update-quantity-to-cart", lineItemId, quantity);
    },
    emptyCart() {
      this.$emit("empty-cart");
    },
    checkout() {
      this.$router.push("/checkout");
    },
  },
};
</script>

<template>
  <div class="flex w-[60%] mx-auto mt-8 overflow-auto mb-20 text-center">
    <div>
      <CartItem
        v-for="lineItem in store.cart.line_items"
        :key="lineItem.id"
        :item="lineItem"
        @update-quantity="sendQuantity"
        @remove-from-cart="$emit('remove-from-cart', $event)"
        class="cart__inner"
      />
      <button
        v-show="this.store.cart.total_items > 0"
        class="border-4 hover:bg-red-500 w-full mx-auto mt-2"
        @click="emptyCart"
      >
        Empty cart
      </button>
      <p class="p-4">
        Total: {{ this.store.cart.subtotal.formatted_with_code }}
      </p>
      <button
        v-show="this.store.cart.total_items > 0"
        class=""
        @click="checkout"
      >
        Checkout
      </button>
    </div>
  </div>
</template>
