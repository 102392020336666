import { defineStore } from "pinia";

export const useStores = defineStore("Stores", {
  state: () => {
    return {
      cart: null,
      showCart: false,
      products: [],
      checkoutToken: null,
      orderData: null,
    };
  },
});
