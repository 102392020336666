<script setup>
import { commerce } from "@/main";
import CartList from "../components/CartList.vue";
import { useStores } from "@/stores/Stores";
</script>

<script>
export default {
  data() {
    return {
      store: useStores(),
    };
  },
  methods: {
    handleUpdateQuantity(lineItemId, quantity) {
      commerce.cart
        .update(lineItemId, { quantity })
        .then((resp) => {
          this.store.cart = resp;
        })
        .catch((error) => {
          console.log("There is an error updating the cart items", error);
        });
    },
    handleRemoveFromCart(lineItemId) {
      commerce.cart
        .remove(lineItemId)
        .then((resp) => {
          this.store.cart = resp;
        })
        .catch((error) => {
          console.log("There was an error updating the cart items", error);
        });
    },
    handleEmptyCart() {
      commerce.cart
        .empty()
        .then((resp) => {
          this.store.cart = resp;
        })
        .catch((error) => {
          console.log("There was an error clearing your cart", error);
        });
    },
  },
};
</script>

<template>
  <div
    class="bg-white h-full right-0 top-12 fixed shadow-2xl w-[50%] md:w-[40%] lg:w-[30%] overflow-auto"
  >
    <CartList
      :cart="store.cart"
      @update-quantity-to-cart="handleUpdateQuantity"
      @remove-from-cart="handleRemoveFromCart"
      @empty-cart="handleEmptyCart"
    />
  </div>
</template>
