<script setup>
import CartSideBar from "./CartSideBar";
import { ShoppingCartIcon } from "@heroicons/vue/24/outline";
import { useStores } from "@/stores/Stores";
</script>

<script>
export default {
  data() {
    return {
      store: useStores(),
    };
  },
  methods: {
    showCart() {
      this.store.showCart = !this.store.showCart;
    },
  },
};
</script>

<template>
  <div class="p-2 flex w-screen top-0 fixed justify-between bg-blue-400">
    <h1 class="">Krafttillskott.se</h1>
    <ShoppingCartIcon class="h-8 w-8 text-black order-last" @click="showCart" />
  </div>
  <CartSideBar v-if="this.store.showCart" />
</template>
